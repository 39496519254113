import React from 'react';
import AnchorLink from '@app/components/ui/links/anchor-link';
import cn from 'classnames';
import moment from 'moment';
import { PostOrPage } from '@tryghost/content-api';
import environments from '@app/configs/environments';

interface ITagTileProps {
    post: PostOrPage;
    className?: string;
}

function TagTime({ post, className }: ITagTileProps) {
    return (
        <div className={cn('P1 flex items-center gap-3', className)}>
            <div className="flex items-center gap-3">
                {post.primary_tag ? (
                    <AnchorLink href={`${environments.GHOST_HOST_URL}/tag/${post.primary_tag.slug}`} onClick={(e) => e.stopPropagation()} className="tag cursor-pointer !leading-none hover:underline">
                        {post.primary_tag.name}
                    </AnchorLink>
                ) : (
                    <p className="tag cursor-default !leading-none">Blog</p>
                )}
            </div>

            <p className="cursor-default border border-b-0 border-r-0 border-t-0 border-l-black-2 pl-3">{moment(post.published_at).format('DD MMMM, YYYY')}</p>
        </div>
    );
}

export default TagTime;
