import React from 'react';
import ImageRenderer from '@app/components/media-renderer/image-renderer';
import AnchorLink from '@app/components/ui/links/anchor-link';
import { ApiResponse, ProductAttributes } from '@app/models/dtos/strapi';
import environments from '@app/configs/environments';
import cn from 'classnames';
import { getProductRoute } from '@app/utils/productUtils';

function ProductsComponent({ products, className }: { products: Array<ApiResponse<ProductAttributes>>; className?: string }) {
    products.sort((a, b) => a.id - b.id);
    return (
        <div className={cn('relative flex w-full flex-col items-center justify-center', className)}>
            <p className="P2 mb-10 w-full !text-black-1">Our Products</p>
            <div className="flex w-full  flex-col gap-[60px] md:gap-[160px]">
                {products.map((product, id) => (
                    <div key={product.id} className={cn('flex  min-h-[336px] w-full  flex-wrap items-center gap-[40px] md:gap-[60px] lg:flex-nowrap xl:gap-[80px] ', id % 2 === 0 ? 'lg:flex-row 2xl:gap-[102px] ' : 'lg:flex-row-reverse 2xl:gap-[82px]')}>
                        {product.attributes.productImage && 'data' in product.attributes.productImage && product.attributes.productImage.data && (
                            <div className="aspect-socialPreview-image  h-[250px] overflow-hidden md:h-[300px] lg:h-[336px]">
                                <ImageRenderer media={`${environments.STRAPI_HOST_URL}${product.attributes.productImage.data.attributes.url}`} isGrayScale={false} className=" !object-contain filter-none " />
                            </div>
                        )}
                        <div className="max-w-[468px] ">
                            {product.attributes.logo && 'data' in product.attributes.logo && (
                                <ImageRenderer media={environments.STRAPI_HOST_URL + product.attributes.logo.data?.attributes.url} className="!h-fit !max-h-8  !w-fit max-w-[186px] !object-contain filter-none" isGrayScale={false} />
                            )}
                            <p className="h4-responsive mb-2 mt-6 text-black-1">{product.attributes.title}</p>
                            <p className="P2 mb-6">{product.attributes.description}</p>
                            <AnchorLink href={getProductRoute(product)} className="small-button flex w-fit">
                                See More
                            </AnchorLink>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ProductsComponent;
