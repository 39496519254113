import HomeContainer from '@app/containers/home';
import Layout from '@app/layouts/_layout';
import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getBannerContent, getLocationsContent, getPosts, getProductsContent, getServicesContent, getSettings, getSocialMedias, getTrustedByContent } from '@app/utils/serverSidePropsUtils';
import { IHomeProps } from '@app/models/props/home';

export async function getServerSideProps({ locale }: any) {
    try {
        const featuredPosts = await getPosts({ limit: 3, filter: 'featured:true' });
        const [settings, locations, products, socialMedias] = await Promise.all([getSettings(), getLocationsContent(), getProductsContent(), getSocialMedias()]);
        const [services, banner, trustedBy] = await Promise.all([getServicesContent(), getBannerContent(), getTrustedByContent()]);
        return {
            props: {
                featuredPosts,
                services,
                settings,
                locations,
                products,
                socialMedias,
                banner,
                trustedBy,
                ...(await serverSideTranslations(locale, ['common'], null, ['en', 'nl']))
            }
        };
    } catch (e) {
        console.log(e);
        return {
            notFound: true
        };
    }
}

const Home = (props: IHomeProps) => {
    const { settings, locations, products, socialMedias } = props;
    return (
        <Layout className="flex flex-col " settings={settings} locations={locations} products={products} socialMedias={socialMedias}>
            <HomeContainer props={props} />
        </Layout>
    );
};

export default Home;
