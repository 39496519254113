import React from 'react';
import ImageRenderer from '@app/components/media-renderer/image-renderer';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { PostOrPage } from '@tryghost/content-api';
import environments from '@app/configs/environments';
import TagTime from '@app/components/blogs/tag-time';

type SizeNames = 'large' | 'small' | 'medium';

interface IBlogCardProps {
    post: PostOrPage;
    size?: SizeNames;
    className?: string;
    isPopularPost?: boolean;
}

const imageSize: Record<SizeNames, string> = {
    large: 'min-h-[379px] h-full w-full',
    small: '!h-[159px] !w-[200px] ',
    medium: '!h-[236px] '
};
const tagTimeFont: Record<SizeNames, string> = {
    large: '!h4-responsive !font-normal',
    small: '!h5-responsive',
    medium: '!h5-responsive'
};
const contentClassName: Record<SizeNames, string> = {
    large: '!h-[170px] mt-4 w-full ',
    small: '',
    medium: 'mt-4'
};

function BlogCard({ post, size = 'medium', className, isPopularPost = false }: IBlogCardProps) {
    const router = useRouter();
    return (
        <div
            onClick={(e) => {
                router.push(`${environments.GHOST_HOST_URL}/${post.slug}`);
            }}
            className={cn('grid h-fit  w-full cursor-pointer grid-flow-row overflow-hidden p-2 outline-1 outline-black-2 hover:outline', className)}
        >
            {post.feature_image ? (
                <div className={cn(' bg-white', imageSize[size])}>
                    <ImageRenderer media={post.feature_image} className={cn('object-cover', imageSize[size])} />
                </div>
            ) : (
                <div className={cn('flex items-center justify-center bg-blue-opacity', imageSize[size])}>
                    <p className="h2-responsive !text-black-2">Blog</p>
                </div>
            )}
            <div className={cn('flex h-full w-full flex-col items-start bg-white text-start', contentClassName[size])}>
                <TagTime post={post} className={tagTimeFont[size]} />
                <p className=" multiline-ellipsis h5 my-2 cursor-default text-black-1">{post.title}</p>
                {post.custom_excerpt ? <p className="P2 multiline-ellipsis w-full cursor-default text-black-2">{post.custom_excerpt}</p> : <p className="P2 multiline-ellipsis w-full cursor-default text-black-2">{post.excerpt}</p>}
            </div>
        </div>
    );
}

export default BlogCard;
