import React from 'react';
import { ApiResponse, ItemDto, ServiceAttributes } from '@app/models/dtos/strapi';
import cn from 'classnames';
import ServiceCard from '@app/components/cards/service-card';
import { ourService } from '@app/constants/services';

interface IServices {
    services: Array<ApiResponse<ServiceAttributes>>;
    isLandingPage?: boolean;
    servicePage?: ApiResponse<ItemDto>;
}

function Services({ services, isLandingPage, servicePage }: IServices) {
    services.sort((a, b) => a.id - b.id);
    return (
        <div className="flex w-full flex-col items-center">
            <div className={cn('flex w-full justify-center', !!isLandingPage && 'bg-blue')}>
                <div className={cn('container flex w-full flex-col', !!isLandingPage ? 'items-center justify-center bg-blue py-[40px] text-center md:py-[68px]' : 'pt-[60px]   md:pt-[160px]')}>
                    <p className={cn('P2 mb-2 mb-5  md:mb-6', !!isLandingPage ? '!text-white' : ' !text-black-1')}>{ourService.title}</p>
                    {!!isLandingPage && <p className="h4-responsive max-w-[733px] !text-white">{ourService.description}</p>}
                    {!isLandingPage && servicePage && <p className="h4-responsive max-w-[733px] !text-black-1" dangerouslySetInnerHTML={{ __html: servicePage.attributes.title }}></p>}
                    <p className={cn('P1 mt-6 max-w-[520px]', !!isLandingPage && 'hidden')}>{servicePage?.attributes.description}</p>
                </div>
            </div>
            <div className=" w-full">
                {services.map((service, id) => (
                    <ServiceCard key={service.id} service={service} id={id} />
                ))}
            </div>
        </div>
    );
}

export default Services;
