import React from 'react';
import AnchorLink from '@app/components/ui/links/anchor-link';
import ArrowTopRight from '@app/components/icons/arrow-top-right';
import { TrustedByAttributes } from '@app/models/dtos/strapi';

export default function TrustedByLeading({ trustedBy }: { trustedBy: TrustedByAttributes }) {
    return (
        <div className="container flex  w-full flex-col justify-between gap-10  py-[80px] md:flex-row">
            <div className="w-full md:max-w-[486px]">
                <p className="P1 !font-medium !text-black-1">Trusted by Leading Business</p>
                <div className="mb-10 mt-6 flex gap-[90px]">
                    <div>
                        <p className="h3-responsive !font-medium">{trustedBy.projects}+</p>
                        <p className="P2">Projects Delivered</p>
                    </div>
                    <div>
                        <p className="h3-responsive !font-medium">{trustedBy.clients}+</p>
                        <p className="P2">Happy Clients</p>
                    </div>
                </div>
                <p className="P2">
                    If your business could benefit from our expertise or would like a consulting, reach out to us at
                    <AnchorLink href={`mailto:${trustedBy.email}`} className="text-blue">
                        {' '}
                        {trustedBy.email}
                    </AnchorLink>
                </p>
            </div>
            <div className="w-full md:max-w-[549px]">
                <p className="h4-responsive">{trustedBy.title}</p>
                <p className="P2 mb-6 mt-3 max-w-[420px]">{trustedBy.description}</p>
                <AnchorLink href="/who-we-are" className="flex w-fit items-center gap-2 !text-blue">
                    <span className="P1 !text-blue">Read More</span>
                    <ArrowTopRight className="!h-3 !w-3 " />
                </AnchorLink>
            </div>
        </div>
    );
}
