import Banner from '@app/components/banner';
import React from 'react';
import Services from '@app/components/services';
import ProductsComponent from '@app/components/products';
import TrustedByLeading from '@app/components/ui/trusted-by-leading';
import BlogList from '@app/components/blogs/blog-list';
import { IHomeProps } from '@app/models/props/home';

export default function HomeContainer({ props }: { props: IHomeProps }) {
    const { featuredPosts, products, services, banner, trustedBy, locations } = props;
    return (
        <div className="flex w-full flex-col items-center">
            <div className="relative flex w-full justify-center">{banner && <Banner banner={banner.attributes} locations={locations} />}</div>
            {trustedBy && (
                <div className="flex w-full justify-center bg-blue-3">
                    <TrustedByLeading trustedBy={trustedBy.attributes} />
                </div>
            )}
            {services && <Services services={services} isLandingPage={true} />}
            <div className="container flex  w-full flex-col">
                {products && products.length !== 0 && <ProductsComponent className="my-[60px] md:my-[160px]" products={products} />}
                {featuredPosts && featuredPosts.length !== 0 && <BlogList posts={featuredPosts} />}
            </div>
        </div>
    );
}
