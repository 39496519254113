import React from 'react';
import AnchorLink from '@app/components/ui/links/anchor-link';
import BlogCard from '@app/components/cards/blog-card';
import { PostsOrPages } from '@tryghost/content-api';
import environments from '@app/configs/environments';

function BlogList({ posts }: { posts: PostsOrPages }) {
    return (
        <div className="bg-brand-200 mb-[60px] flex   w-full justify-center md:mb-[160px]">
            <div className="flex h-full w-full flex-col flex-wrap items-center justify-center lg:container">
                <p className="P2 w-full !text-black-1">Blogs</p>
                <div className="my-5 grid w-full grid-flow-row grid-cols-1 gap-8 sm:grid-cols-2 md:my-10 md:grid-cols-2 md:gap-10 lg:grid-cols-3">
                    {posts.map((post) => (
                        <BlogCard post={post} key={post.uuid} />
                    ))}
                </div>
                {posts.length == 3 && (
                    <AnchorLink href={environments.GHOST_HOST_URL} className="small-button flex items-center justify-center">
                        See All
                    </AnchorLink>
                )}
            </div>
        </div>
    );
}

export default BlogList;
