import React from 'react';
import HeroBackground from '@app/assets/images/hero-background.png';
import ImageRenderer from '@app/components/media-renderer/image-renderer';
import { ApiResponse, BannerAttributes, LocationAttributes } from '@app/models/dtos/strapi';
import Button from '@app/components/ui/button';
import { useFullScreenModal } from '@app/components/modal-views/full-screen-modal-context';
import RadialGradient from '@app/components/ui/radial-gradient';

interface IBannerProps {
    banner: BannerAttributes;
    locations: Array<ApiResponse<LocationAttributes>> | null | undefined;
}

export default function Banner({ banner, locations }: IBannerProps) {
    const { openModal } = useFullScreenModal();
    return (
        <div className="container flex w-full flex-col items-center justify-center bg-white  py-[60px] md:py-[100px]  2xl:py-[150px]">
            <RadialGradient className="!top-0 -left-[50px] !h-[172px] !w-[279px] !rounded-[279px] " />
            <h1 dangerouslySetInnerHTML={{ __html: banner.title }} className="h1-responsive max-w-[1079] text-center !font-normal"></h1>
            <p className="h5 mb-10 mt-6 max-w-[610px] text-center !text-black-2">{banner.description}</p>
            <div>
                <Button size="large" className="z-20" onClick={() => openModal('CONTACT_US', { locations })}>
                    Contact Us
                </Button>
            </div>
            <div className="banner-icon-desktop absolute bottom-0 right-0 w-full max-w-[400px] lg:max-w-[600px] 2xl:max-w-[807px]">
                <ImageRenderer media={HeroBackground} isGrayScale={false} className="filter-none" />
            </div>
        </div>
    );
}
