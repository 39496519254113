import React from 'react';
import cn from 'classnames';
import ImageRenderer from '@app/components/media-renderer/image-renderer';
import { ApiResponse, ServiceAttributes } from '@app/models/dtos/strapi';
import environments from '@app/configs/environments';
import HeroBackground from '@app/assets/images/hero-background.png';

function ServiceCard({ service, id }: { service: ApiResponse<ServiceAttributes>; id: number }) {
    return (
        <div className={cn('relative sticky top-0 flex w-full items-center justify-center md:min-h-screen', id % 2 == 0 ? 'bg-white   ' : 'bg-black-1')}>
            <div className="container flex w-full flex-wrap gap-[30px] py-[60px]  md:flex-nowrap  md:py-[120px] lg:gap-[100px]">
                <div className="aspect-square h-full max-h-[250px] w-full max-w-[250px] md:max-h-[400px] md:max-w-[400px] ">
                    {service.attributes.image.data && <ImageRenderer media={environments.STRAPI_HOST_URL + service.attributes.image.data.attributes.url} />}
                </div>
                <div className="flex flex-col gap-4 ">
                    <h2 className={cn('h2-responsive', id % 2 === 0 ? 'text-black-1' : '!text-white')}>{service.attributes.title}</h2>
                    <p className={cn('P1 w-full max-w-[420px] ', id % 2 === 0 ? '!text-black-2' : '!text-white-2')}>{service.attributes.description}</p>
                </div>
            </div>
            {id % 2 !== 0 && (
                <div className="banner-icon-desktop absolute bottom-0 right-0 w-full max-w-[400px] lg:max-w-[600px] 2xl:max-w-[807px]">
                    <ImageRenderer media={HeroBackground} isGrayScale={false} className="filter-none" />
                </div>
            )}
        </div>
    );
}

export default ServiceCard;
